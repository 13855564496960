<script setup lang="ts">
/**
 * UiStack component
 *
 * Automatically layout page-level and section-level elements
 *
 * @see https://github.com/forged-com/forgd/pull/1642
 */
const props = withDefaults(defineProps<{
  // value props
  dir?: 'row' | 'col'
  gap?: 'xs' | 'sm' | 'md' | 'lg' // could maybe refactor to section | page | whatever

  // shorthand props
  row?: boolean // shorthand for dir="row"
  page?: boolean // shorthand for gap="md"

  // shorthand gap
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
}>(), {
  dir: 'col',
  gap: 'sm',
})

const gaps = {
  xs: `gap-2`,
  sm: `gap-5`,
  md: `gap-10`,
  lg: `gap-20`,
}

// value is NOT computed, so classes will calculate only once
const classes = [
  props.dir === 'row' || props.row ? 'flex-row' : 'flex-col',
  props.page && gaps.md,
  props.xs && gaps.xs,
  props.sm && gaps.sm,
  props.md && gaps.md,
  props.lg && gaps.lg,
]

if (!String(classes).includes('gap')) {
  classes.push(gaps[props.gap])
}
</script>

<template>
  <div data-lo="UiStack" class="flex" :class="classes">
    <slot />
  </div>
</template>
